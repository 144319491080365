import { ProjectStatusView, ProjectView } from '@/models/project.model';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios from 'axios';

export interface ProjectsState {
    projects: ProjectView[];
}

const mutations: MutationTree<ProjectsState> = {
    setProjects(state: ProjectsState, projects: ProjectView[]) {
        state.projects = projects;
    },
    updateProject(state: ProjectsState, { id, updatedItem }: { id: string; updatedItem: ProjectView }) {
        const index = state.projects.findIndex(e => e.id === id);
        if (index !== -1) {
            state.projects.splice(index, 1, updatedItem);
        } else {
            state.projects.push(updatedItem);
        }
    },
    deleteProject(state: ProjectsState, { id }: { id: string }) {
        const index = state.projects.findIndex(e => e.id === id);
        if (index !== -1) {
            state.projects.splice(index, 1);
        }
    },
    resetProject(state: ProjectsState, { id }: { id: string }) {
        const index = state.projects.findIndex(e => e.id === id);
        if (index !== -1) {
            state.projects[index].status = 'FILE_UPLOADED';
            state.projects[index].tries = 0;
        }
    },
    resetProjectFrom(state: ProjectsState, { id, status }: { id: string; status: string }) {
        const index = state.projects.findIndex(e => e.id === id);
        if (index !== -1) {
            state.projects[index].status = status;
            state.projects[index].tries = 0;
        }
    },
};

const actions: ActionTree<ProjectsState, any> = {
    async getProjects({ commit }: { commit: any }) {
        const response = await axios.get<ProjectView[]>('/api/upload');
        if (response.status === 200) {
            commit('setProjects', response.data);
        }
        commit('setProjects', response.data);
    },
    async getProject({ commit }: { commit: any }, id: string) {
        const response = await axios.get<ProjectView[]>(`/api/upload/${id}`);
        commit('updateProject', { id, updatedItem: response.data });
    },
    async getProjectHistory(_, id: string) {
        const response = await axios.get<ProjectStatusView[]>(`/api/upload/history/${id}`);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    },
    async getPcPoses(_, id: string) {
        const response = await axios.get(`/api/projectFile/${id}/poses`);
        if (response.status === 200) {
            return response.data;
        }
        return [];
    },
    async generateDownloadToken(_, { id, type, fileId }: { id: string; type: string, fileId: string }) {
        let url = `/api/project/${id}/generateDownloadToken/${type}`;
        if (fileId && fileId.length > 0) {
            url += `/${fileId}`;
        }
        const response = await axios.get(url);
        if (response.data) {
            // Use the provided link to download the file
            const url = window.location.origin.replace(':8080', ':5212') + response.data;
            const link = document.createElement('a');
            link.setAttribute('download', 'download');
            link.setAttribute('href', url);
            document.body.appendChild(link);
            link.click();
            (link as any).parentNode.removeChild(link); // Clean up
        }
        // commit('updateProject', { id, updatedItem: response.data });
    },
    async updateProject({ commit }: { commit: any }, project: ProjectView) {
        const response = await axios.put(`/api/upload/${project.id}`, project);
        if (response.status >= 200 && response.status <= 299) {
            commit('updateProject', { id: project.id, updatedItem: project });
        }
    },
    async deleteProject({ commit }: { commit: any }, id: string) {
        const response = await axios.delete(`/api/project/${id}`);
        if (response.status >= 200 && response.status <= 299) {
            commit('deleteProject', { id });
        }
    },
    async resetProject({ commit }: { commit: any }, id: string) {
        const response = await axios.post(`/api/project/${id}/reset`);
        if (response.status >= 200 && response.status <= 299) {
            commit('resetProject', { id });
        }
    },
    async resetProjectFrom({ commit }: { commit: any }, { id, projectStatusId }: { id: string; projectStatusId: string }) {
        const response = await axios.post(`/api/project/${id}/resetfrom/${projectStatusId}`);
        if (response.status >= 200 && response.status <= 299) {
            commit('resetProjectFrom', { id: response.data.id, status: response.data.status });
            return response.data;
        }
    },
    async inviteUsers(_, { id, emails }: { id: string; emails: string }) {
        const response = await axios.post(`/api/project/${id}/invite`, { emails: emails });
        if (response.status >= 200 && response.status <= 299) {
            console.log('success');
        }
    },
};

const getters: GetterTree<ProjectsState, any> = {
    projectById: (state: ProjectsState) => (id: string) => {
        return (state.projects && state.projects.find(e => e?.id === id)) || null;
    },
};

export const projectsStore = {
    namespaced: true,
    state: () => ({
        projects: [] as ProjectView[],
    }),
    mutations,
    actions,
    getters,
};
