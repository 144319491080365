<template>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-half">
                    <div style="max-width:400px;margin: auto;">
                        <div class="content has-text-centered">
                            <div class="box">
                                <div style="height: 320px;position:relative;" v-show="!hasImage" class="level-item has-text-centered">
                                    <loading-spinner-dotted></loading-spinner-dotted>
                                    <h3 v-show="project.status !== 'FILE_PROCESSING_FINISHED'" style="position:absolute; left: calc(50% - 20px);" class="title is-5" v-html="getCurrentProjectStatePercentage(project.status)"></h3>
                                </div>
                                <img v-show="hasImage" @load="hasImage = true" @error="hasImage = false"
                                    style="height: 320px;" v-loadimage="{ 'projectId': route.params.id }"
                                    alt="Project preview" />
                                <h2 class="title is-4">{{ project.name || '&nbsp;' }}</h2>
                                <div v-if="!project.status?.includes('_FAILED') && hasImage && project.status !== 'FILE_PROCESSING_FINISHED'" class="loading-info is-flex is-justify-content-space-around" style="position:relative;height: 30px;">
                                    <div class="spinner-wrapper">
                                        <loading-spinner-dotted></loading-spinner-dotted>
                                    </div>
                                    <span class="loading-percentage" v-html="getCurrentProjectStatePercentage(project.status)"></span>
                                </div>
                                <div v-if="project.tries > 9 && project.status?.includes('_FAILED')">
                                    {{ $t('ProjectDetail.Error') }}
                                </div>
                                <div v-else-if="project.status == null || project.status == ''">
                                    {{ $t('ProjectDetail.Processing') }}
                                </div>
                                <div v-else-if="project.status !== 'FILE_PROCESSING_FINISHED'">
                                    {{ $t('ProjectDetail.Processing') }}
                                </div>
                                <p v-if="store.state.auth.isAdmin">{{ $t('ProjectDetail.Status') }}: {{ project.status }}
                                    <br />
                                    Tries: {{ project.tries }}
                                    <br />
                                    Size: {{ project.fileSize }} MB ({{ project.fileName }})
                                    <br />
                                    #Files: {{ project.projectFiles?.length ?? 0 }} User: {{ project.createdByUser }}
                                </p>
                                <p class="">{{ $t('General.CreatedOn') }}
                                    {{ formatDate(project.dateCreated, 'dd.MM.yyyy HH:mm') }}</p>
                                <p v-if="store.state.auth.isAdmin" class="">{{ $t('General.LastUpdate') }}:
                                    {{ formatDate(project.dateModified, 'dd.MM.yyyy HH:mm') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="column is-half">
                    <h3 class="subtitle is-3 has-text-centered">
                        {{ $t('ProjectDetail.Title') }}
                    </h3>
                    <!-- add a list of 5 items with an fontawesome icon -->
                    <ul class="grid-list mb-5" style="margin:auto;">
                        <li v-if="isAdmin" class="is-clickable subtitle is-5" @click="downloadPointcloud()">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>{{ $t('General.Pointcloud') }}</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id" @click="downloadE57(file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{index}}.e57</span>
                            </span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5" @click="download3DM()">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.3dm</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id" @click="downloadPLY(file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{ index }}.ply</span>
                            </span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5" @click="downloadDXF()">
                            <!-- shopping-cart -->
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>merged.dxf</span>
                        </li>
                        <li v-if="isAdmin" class="is-clickable subtitle is-5">
                            <span :title="file.id" v-for="(file, index) in project.projectFiles" :key="file.id" @click="downloadDXF(file.id)">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                                <span>{{index}}.dxf</span>
                            </span>
                            <!-- <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.e57</span> -->
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5" @click="downloadIFC()">
                            <!-- <font-awesome-icon icon="download" class="mr-2" /> -->
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.ifc</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5" @click="downloadDWG()">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.dwg</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5" @click="downloadRVT()">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.rvt</span>
                        </li>
                        <li v-if="project.status === 'FILE_PROCESSING_FINISHED'" class="is-clickable subtitle is-5" @click="downloadPLN()">
                            <i class="nyne-icon nyne-icon-download mr-2"></i>
                            <span>.pln</span>
                        </li>
                    </ul>
                    <div v-if="!isInviteUsersOpen" class="field material-input has-text-centered mt-5">
                        <div class="control">
                            <button class="button is-primary" @click="toggleInviteUsers">
                                {{ $t('ProjectDetail.Share') }}</button>
                        </div>
                    </div>
                    <div v-if="isInviteUsersOpen" class="field material-input mt-5 box">
                        <form v-form-validate="{ submitMethod: sendInviteUsers, locale: locale }">
                            <div class="control">
                                <input v-model="inviteUsers" class="input" type="text" placeholder="" required>
                                <label class="label">{{ $t('ProjectDetail.InviteUsers.Title') }}</label>
                            </div>
                            <div class="is-flex is-fullwidth is-justify-content-space-evenly mt-4">
                                <button type="submit" class="button is-primary">{{ $t('ProjectDetail.Share') }}</button>
                                <button type="button" class="button is-danger" @click="toggleInviteUsers">
                                    {{ $t('General.Abort') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isAdmin" class="container">
            <div class="columns">
                <!-- show list of projectHistory -->
                <div class="column is-full">
                    <h3 class="subtitle is-3 has-text-centered">
                        {{ $t('ProjectDetail.History.Title') }}
                    </h3>
                    <SortableTable :columns="columns" :rows="projectHistory" :date-format="'dd.MM.yyyy HH:mm:ss'"></SortableTable>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <h3 class="subtitle is-3 has-text-centered">
                        {{ $t('Pointcloud Poses') }}
                    </h3>
                    <SortableTable :columns="pcPoseColumns" :rows="pcPoses" :date-format="'dd.MM.yyyy HH:mm:ss'"></SortableTable>
                </div>
            </div>
            
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, reactive, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '../store';
import { PointcloudPose, ProjectStatusView, ProjectView } from '@/models/project.model';
import { formatDate } from '@/utils/formatBytes';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerDotted from './LoadingSpinnerDotted.vue';
import { TableColumn } from '@/models';
import SortableTable from './SortableTable.vue';


const { t, locale } = useI18n();
const store = useStore();
const route = useRoute();
const project: ProjectView = reactive<ProjectView>({
    id: '',
    tries: 0,
    projectFiles: [],
});
const isAdmin = computed(() => store.state.auth.isAdmin);
const isInviteUsersOpen = ref<boolean>(false);
const inviteUsers = ref<string>('');
const hasImage = ref(false);
const projectHistory = ref<ProjectStatusView[]>([]);
const pcPoses = ref<PointcloudPose[]>([]);

store.commit('app/setLayout', 'default');

const columns: TableColumn[] = [
    {
        field: 'id',
        title: '',
        sortable: false,
        actions: {
            reset: async (projectStatus: any) => {
                if (store.state.auth.isAdmin) {
                    const result = await store.dispatch('project/resetProjectFrom', { id: project.id, projectStatusId: projectStatus.id });
                    project.status = result.status;
                    store.commit('app/showToast', {
                        message: t('Projects.Reset.Success'),
                        type: 'success',
                    });
                    
                    projectHistory.value = await store.dispatch('project/getProjectHistory', project.id);
                }
            },
        },
    },
    {
        field: 'dateCreated',
        fieldType: 'date',
        title: 'ProjectDetail.History.Date',
        sortable: true,
    },
    { field: 'fileId', title: 'ProjectDetail.History.FileId', sortable: true },
    { field: 'status', title: 'ProjectDetail.History.Status', sortable: true },
    { field: 'tries', title: 'ProjectDetail.History.Tries', sortable: true },
    { field: 'serverVersion', title: 'ProjectDetail.History.ServerVersion', sortable: true },
    { field: 'processingServer', title: 'ProjectDetail.History.ProcessingServer', sortable: true },
];

const pcPoseColumns: TableColumn[] = [
    { field: 'scanIndex', title: 'ProjectDetail.Pointcloud.ScanIndex', sortable: true },
    { field: 'transX', title: 'Translation X', sortable: true },
    { field: 'transY', title: 'Translation Y', sortable: true },
    { field: 'transZ', title: 'Translation Z', sortable: true },
    { field: 'rotX', title: 'Rotation X', sortable: true },
    { field: 'rotY', title: 'Rotation Y', sortable: true },
    { field: 'rotZ', title: 'Rotation Z', sortable: true },
    { field: 'rotW', title: 'Rotation W', sortable: true },
    { field: 'scaleX', title: 'Scale X', sortable: true },
    { field: 'scaleY', title: 'Scale Y', sortable: true },
    { field: 'scaleZ', title: 'Scale Z', sortable: true },
    { field: 'comment', title: 'Comment', sortable: true },
];

const downloadE57 = async (fileId: string) => {
    if (!fileId) {
        await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'e57' });
        return;
    }
    
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'e57', fileId: fileId });
};
const download3DM = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: '3dm' });
}
const downloadPLY = async (fileId: string) => {
    if (!fileId) {
        await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'ply' });
        return;
    }
    
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'ply', fileId: fileId });
}
const downloadDXF = async (fileId?: string) => {
    if (!fileId) {
        await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'dxf' });
        return;
    }

    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'dxf', fileId: fileId });
};
const downloadRVT = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'rvt' });
};
const downloadIFC = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'ifc' });
};
const downloadDWG = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'dwg' });
};
const downloadPLN = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'pln' });
};
const downloadPointcloud = async () => {
    await store.dispatch('project/generateDownloadToken', { id: route.params.id, type: 'pointcloud' });
};

const toggleInviteUsers = async () => {
    isInviteUsersOpen.value = !isInviteUsersOpen.value;
}
const sendInviteUsers = async () => {
    if (!inviteUsers.value || inviteUsers.value.trim() === '') return;
    await store.dispatch('project/inviteUsers', { id: route.params.id, emails: inviteUsers.value });
    inviteUsers.value = '';
    isInviteUsersOpen.value = !isInviteUsersOpen.value;
    store.commit('app/showToast', {
        message: t('ProjectDetail.InviteUsers.Success'),
        type: 'success',
    });
}

const getCurrentProjectStatePercentage = (status?: string) => {
    // these are my updatestates:
    // START = 0,
    //  FILE_UPLOAD_FAILED = 1,
    //  FILE_UPLOADED = 2,
    //  FILE_PREPROCESSING_STARTED = 3,
    //  FILE_PREPROCESSING_REQUEST_FAILED = 4,
    //  FILE_PREPROCESSING_FAILED = 5,
    //  E57_EXTRACTED = 6,
    //  FILE_PREPROCESSING_FINISHED = 7,
    //  KPCONV_QUEUED = 8,
    //  PLY_CONVERSION_STARTED = 9,
    //  KPCONV_FAILED = 10,
    //  PLY_CONVERSION_FAILED = 11,
    //  PLY_CONVERSION_FINISHED = 12,
    //  PLY_ANNOTATION_STARTED = 13,
    //  PLY_ANNOTATION_FAILED = 14,
    //  PLY_ANNOTATION_FINISHED = 15,
    //  DXF_CONVERSION_QUEUED = 16,
    //  DXF_CONVERSION_STARTED = 17,
    //  DXF_CONVERSION_FAILED = 18,
    //  DXF_CONVERSION_FINISHED = 19,
    //  BIM_CONVERSION_QUEUED = 20,
    //  BIM_CONVERSION_STARTED = 21,
    //  BIM_CONVERSION_FAILED = 22,
    //  BIM_CONVERSION_FINISHED = 23,
    //  FILE_PROCESSING_FINISHED = 24

    if (!status) {
        return "&nbsp;1%";
    }
    if (status === "START") {
        return "10%";
    } else if (status === "FILE_UPLOADED") {
        return "15%";
    } else if (status === "FILE_PREPROCESSING_STARTED") {
        return "20%";
    } else if (status === "E57_EXTRACTED") {
        return "26%";
    } else if (status === "FILE_PREPROCESSING_FINISHED") {
        return "33%";
    } else if (status === "KPCONV_QUEUED") {
        return "34%";
    } else if (status === "PLY_CONVERSION_STARTED") {
        return "37%";
    } else if (status === "PLY_CONVERSION_FINISHED") {
        return "45%";
    } else if (status === "PLY_ANNOTATION_STARTED") {
        return "46%";
    } else if (status === "PLY_ANNOTATION_FINISHED") {
        return "49%";
    } else if (status === "DXF_CONVERSION_QUEUED") {
        return "50%";
    } else if (status === "DXF_CONVERSION_STARTED") {
        return "52%";
    } else if (status === "DXF_CONVERSION_FINISHED") {
        return "60%";
    } else if (status === "BIM_CONVERSION_QUEUED") {
        return "61%";
    } else if (status === "BIM_CONVERSION_STARTED") {
        return "65%";
    } else if (status === "BIM_CONVERSION_FINISHED" || status === "FILE_PROCESSING_FINISHED" ) {
        return "99%";
    } else {
        return "";
    }
}


watch(
    () => route.params.id as string,
    async id => {
        if (id) {
            let foundItem = store.getters['project/projectById'](id);
            if (foundItem == null) {
                await store.dispatch('project/getProject', id);
                foundItem = store.getters['project/projectById'](id);
            }

            if (foundItem == null) {
                // still no data present, id does not exist
            } else {
                await store.dispatch('project/getProject', id);
                foundItem = store.getters['project/projectById'](id);
            }

            if (foundItem) {
                // console.log("found item", foundItem);
                Object.assign(project, foundItem);
            } else {
                // redirect zu 404, not found
            }

            if (store.state.auth.isAdmin) {
                projectHistory.value = await store.dispatch('project/getProjectHistory', id);
                pcPoses.value = await store.dispatch('project/getPointcloudPoses', id);
            }
        }
    },
    { immediate: true },
);
</script>
<style scoped lang="scss">
// .grid-list {
//     // text-align: center;
//     // width: 250px;
// }

.grid-list li {
    display: grid;
    grid-template-columns: auto auto 1fr;
    /* Spacing, icon, and text */
    align-items: center;
    gap: 8px;
    /* space between icon and text */
    cursor: pointer;
}

.grid-list .nyne-icon {
    margin-left: -8px;
    &::before {
        font-size: 1.7rem;
    }
}

.grid-list li::before {
    content: '';

    /* Necessary for the pseudo-element to display */
    @media screen and (min-width: 768px) {
        width: 125px;
    }

    @media screen and (min-width: 850px) {
        width: 155px;
    }

    @media screen and (min-width: 1024px) {
        width: 190px;
    }

    @media screen and (min-width: 1216px) {
        width: 230px;
    }

    @media screen and (min-width: 1408px) {
        width: 280px;
    }

    /* Adjust this value for desired spacing */
    display: inline-block;
    /* Or block, depending on your layout needs */
}

// .grid-list li .icon {
//     /* Adjust icon styles if necessary */
// }


// .clickable-item:hover {
//     /* Style for hover state, if needed */
// }
</style>
