<template>
    <section class="section">
        <div class="container">
            <!-- <h1 class="title is-2 has-text-centered">{{ $t('Projects.Title') }}</h1> -->
            <div class="columns is-multiline">
                <div class="column is-one-quarter" v-for="project in projects" :key="(project.id)">
                    <div class="box">
                        <div class="content has-text-centered project" style="position:relative;">
                            <div class="actions">
                                <font-awesome-icon v-if="!showDelete" class="icon-circle" @click="showDelete = project.id" icon="close" />
                                <button v-if="showDelete" @click="deleteProject(project.id)" class="button button-delete is-danger">{{ $t('General.Delete') }}</button>
                                <i v-if="store.state.auth.isAdmin" title="Reset project" @click="resetProject(project.id)" class="nyne-icon" :class="'nyne-icon nyne-icon-einstellungen'"></i>
                                <!-- <font-awesome-icon @click="deleteProject(project.id)" icon="trash"></font-awesome-icon> -->
                            </div>
                            <router-link :to="'/project/' + project.id">
                                <!--     alt="Project preview" /> -->
                                <div style="height: 165px;" v-show="previewImgs.indexOf(project.id) === -1"
                                    class="level-item has-text-centered">
                                    <loading-spinner-dotted></loading-spinner-dotted>
                                </div>
                                <img v-show="previewImgs.indexOf(project.id) > -1" @load="loadedPreviewImage(project.id)"
                                    style="max-height: 160px;" v-loadimage="{ 'projectId': project.id }"
                                    alt="Project preview" />
                                <h2 class="title is-4">{{ project.name || '&nbsp;' }}</h2>
                                <p v-if="store.state.auth.isAdmin">{{ $t('ProjectDetail.Status') }}: {{ project.status }}
                                    <br />
                                    Tries: {{ project.tries }}
                                </p>
                                <span class="">{{ formatDate(project.dateCreated, 'dd.MM.yyyy HH:mm') }}</span>
                                <p v-if="store.state.auth.isAdmin" class="">{{ $t('General.LastUpdate') }}:
                                    {{ formatDate(project.dateModified, 'dd.MM.yyyy HH:mm') }}</p>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="projects.length === 0" class="column">
                    <h2 class="title has-text-centered">{{ $t('Projects.NotUploadedYet') }}</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, ref } from 'vue';
import { useStore } from '../store';
import { formatDate } from '@/utils/formatBytes';
import { useI18n } from 'vue-i18n';
import LoadingSpinnerDotted from './LoadingSpinnerDotted.vue';

const { t } = useI18n();
const store = useStore();
const projects = computed(() => store.state.project.projects);
const previewImgs: string[] = reactive([]);
const showDelete = ref("")

store.commit('app/setLayout', 'default');

const loadedPreviewImage = (id: string) => {
    previewImgs.push(id);
};
const deleteProject = (id: any) => {
    const title = 'Delete Project';
    const text = t('Projects.Delete.Text');
    const action = async () => {
        await store.dispatch('project/deleteProject', id);

        store.commit('app/showToast', {
            message: t('Projects.Delete.Success'),
            type: 'success',
        });
    };

    store.commit('app/showConfirmDialog', { title, text, action });
};

const resetProject = (id: any) => {
    const title = 'Reset Project';
    const text = t('Projects.Reset.Text');
    const action = async () => {
        await store.dispatch('project/resetProject', id);

        store.commit('app/showToast', {
            message: t('Projects.Reset.Success'),
            type: 'success',
        });
    };

    store.commit('app/showConfirmDialog', { title, text, action });
};

onMounted(async () => {
    await store.dispatch('project/getProjects');
});
</script>

<style scoped lang="scss">
.actions {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-align: right;
    .button-delete {
        width: 100%;
    }
}

.actions:hover {
    color: #000;
}
</style>