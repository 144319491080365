<template>
    <section class="section">
        <div class="xeokit-viewer-wrapper">
            <canvas id="xeokit_canvas" class="xeokit-canvas"></canvas>
            <canvas id="navcube" class="nav-cube"></canvas>
            <!-- <div id="minimap" class="minimap"></div> -->

            <div class="ruler-buttons">
                <ul class="menu-list">
                    <li>
                        <router-link :to="`/projectshare/${projectId}`" class="btn button is-primary menu-item">
                            <div class="icon-wrapper">
                                <font-awesome-icon icon="user-plus" />
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <button class="btn button is-primary" title="Open Download Files-Menu menu-item"
                            @click="downloadPointcloud()">
                            <div class="icon-wrapper" style="margin-left: -5px; margin-top: 9px">
                                <i class="nyne-icon nyne-icon-download mr-2"></i>
                            </div>
                        </button>
                    </li>
                    <li>
                        <button @click="fitView" class="btn button is-primary menu-item">
                            <div class="icon-wrapper" style="margin-left: -9px; margin-top: 13px">
                                <font-awesome-icon icon="location-arrow" />
                            </div>
                        </button>
                    </li>
                    <li>
                        <button @click="activateDistanceMeasurement" class="btn button menu-item"
                            :class="{ 'is-primary': !isMeasuring, 'is-selected': isMeasuring }">
                            <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                <font-awesome-icon icon="ruler" />
                            </div>
                        </button>
                    </li>
                    <li>
                        <button style="width: 100px;" v-if="isMeasuring" @click="resetMeasurements"
                            class="btn button is-danger menu-item">
                            ❌ Ruler
                        </button>
                    </li>
                    <li>
                        <button @click="activateAngleMeasurement" class="btn button menu-item"
                            :class="{ 'is-primary': !isMeasuringAngle, 'is-selected': isMeasuringAngle }">
                            <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                                <font-awesome-icon icon="chevron-right" />
                            </div>
                        </button>
                    </li>
                    <li><button @click="toggle2D3D" class="btn button menu-item is-primary">
                        <div class="icon-wrapper" style="margin-left: -8px; margin-top: 11px">
                            <font-awesome-icon icon="cube" />
                        </div>
                    </button></li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { Viewer, XKTLoaderPlugin, NavCubePlugin, DistanceMeasurementsPlugin, DistanceMeasurementsMouseControl, PointerLens, AngleMeasurementsPlugin, AngleMeasurementsMouseControl } from '@xeokit/xeokit-sdk';
import { useStore } from '@/store';
import { useRoute } from 'vue-router';
import IfcOpenHouse2x3 from '@/assets/media/IfcOpenHouse2x3.ifc.v11.xkt';
import { onBeforeUnmount } from 'vue';

const store = useStore();
const route = useRoute();
// State to track the distance measurement plugin
let distanceMeasurementPlugin: DistanceMeasurementsPlugin | null = null;
let distanceMeasurementControl: DistanceMeasurementsMouseControl | null = null;
const isMeasuring = ref(false); // Track if measurement mode is active
const isMeasuringAngle = ref(false); // Track angle measurement mode
const is3D = ref(true); // Track whether the view is in 3D or 2D
let viewer: Viewer | null = null; // Track the Viewer instance
let pointerLens: PointerLens | null = null;
let angleMeasurementPlugin: AngleMeasurementsPlugin | null = null;
let angleMeasurementControl: AngleMeasurementsMouseControl | null = null;

store.commit('app/setLayout', 'centered');

// get projectId from the route
const projectId = route.params.id;

const setupViewer = () => {
    viewer = new Viewer({
        canvasId: 'xeokit_canvas',
        transparent: true,
        dtxEnabled: true,
    });

    viewer.camera.eye = [-3.933, 2.855, 27.018];
    viewer.camera.look = [4.400, 3.724, 8.899];
    viewer.camera.up = [-0.018, 0.999, 0.039];

    const xktLoader = new XKTLoaderPlugin(viewer);

    xktLoader.load({
        id: 'myModel',
        // Hardcoded path to your file in 'assets/media'
        src: IfcOpenHouse2x3,
        saoEnabled: true,
        edges: true,
        //   dtxEnabled: true,
    });

    const cube = new NavCubePlugin(viewer, {
        canvasId: "navcube", // The container for the nav cube
        visible: true, // Show the cube
        cameraFly: true, // Smooth camera transition when clicking cube sides
        // size: [150, 150], // Size of the cube
    });
    console.log("cube", cube);

    // Initialize the DistanceMeasurementsPlugin for distance measurement
    distanceMeasurementPlugin = new DistanceMeasurementsPlugin(viewer);

    distanceMeasurementPlugin.on('measurementStart', (distanceMeasurement) => {
        console.log('Measurement started', distanceMeasurement);
    });

    distanceMeasurementPlugin.on('measurementEnd', (distanceMeasurement) => {
        console.log('Measurement ended');
        console.log(`Measured distance: ${distanceMeasurement.value} meters`);
    });

    // Initialize the AngleMeasurementsPlugin
    angleMeasurementPlugin = new AngleMeasurementsPlugin(viewer);
    angleMeasurementControl = new AngleMeasurementsMouseControl(angleMeasurementPlugin, {
        snapping: true,
    });

    pointerLens = new PointerLens(viewer, {
        zoomFactor: 5, // Zoom level (higher is more zoomed-in)
        containerId: 'minimap', // Attach to the minimap container,
        active: true,
        lensPosMarginLeft: 10,
        lensPosMarginTop: 100,
    });

    // Initialize mouse control for distance measurements
    distanceMeasurementControl = new DistanceMeasurementsMouseControl(distanceMeasurementPlugin, {
        pointerLens: pointerLens, // To magnify the mouse pointer location
        snapping: true, // Enable snapping to edges and vertices
    });


    // Activate the measurement control
    // distanceMeasurementControl.activate();
};

// Function to activate distance measurement
const activateDistanceMeasurement = () => {
    if (distanceMeasurementControl && !isMeasuring.value) {
        // Enable the plugin and set measurement mode
        // distanceMeasurementControl.reset();
        if (angleMeasurementControl && isMeasuringAngle.value) {
            angleMeasurementControl.deactivate();
            isMeasuringAngle.value = false;
        }
        distanceMeasurementControl.activate();
        isMeasuring.value = true;
        console.log("Measurement mode activated. Click to select points.");
    } else if (distanceMeasurementControl && isMeasuring.value) {
        distanceMeasurementControl.deactivate();
        isMeasuring.value = false;
        console.log("Measurement mode deactivated.");
    }
};

const activateAngleMeasurement = () => {
    if (angleMeasurementControl && !isMeasuringAngle.value) {
        // angleMeasurementControl.reset();
        angleMeasurementControl.activate();
        if (distanceMeasurementControl && isMeasuring.value) {
            distanceMeasurementControl.deactivate();
            isMeasuring.value = false;
        }
        isMeasuringAngle.value = true;
        console.log("Angle measurement mode activated.");
    } else if(angleMeasurementControl && isMeasuringAngle.value) {
        angleMeasurementControl.deactivate();
        isMeasuringAngle.value = false;
        console.log("Angle measurement mode deactivated.");
    }
};

// Function to stop and reset measurements
const resetMeasurements = () => {
    if (distanceMeasurementPlugin) {
        distanceMeasurementPlugin.clear(); // Clear all measurements
        isMeasuring.value = false; // Set measuring state to false
        console.log("All measurements have been cleared.");
    }
};

// Function to clean up the viewer when the component is unmounted
const cleanupViewer = () => {
    if (distanceMeasurementControl) {
        distanceMeasurementControl.deactivate();
        distanceMeasurementControl = null;
    }
    if (distanceMeasurementPlugin) {
        distanceMeasurementPlugin.clear();
        distanceMeasurementPlugin = null;
    }
    if (angleMeasurementControl) {
        angleMeasurementControl.deactivate();
        angleMeasurementControl = null;
    }
    if (angleMeasurementPlugin) {
        angleMeasurementPlugin.clear();
        angleMeasurementPlugin = null;
    }
    if (viewer) {
        viewer.destroy(); // Destroy the viewer and release resources
        viewer = null;
        console.log("Viewer destroyed and resources released.");
    }
};

const fitView = () => {
    if (viewer) {
        // Get all object IDs in the scene
        const objectIDs = Object.keys(viewer.scene.objects); // Retrieve all object IDs

        // Fit the camera to the bounding box of the entire scene
        viewer.cameraFlight.flyTo({
            aabb: viewer.scene.getAABB(objectIDs) // Pass all object IDs to get the bounding box
        });
    }
};

const toggle2D3D = () => {
    if (!viewer) {
        return; 
    }
    
    if (is3D.value) {
        // Switch to 2D (top-down orthographic view)
        viewer.cameraFlight.flyTo({
            eye: [0, 100, 0], // Move the camera above the model
            look: [0, 0, 0],  // Look directly down at the model
            up: [0, 0, -1],   // Align the camera to a top-down view
            projection: "ortho" // Use orthographic projection for 2D
        });
        console.log("Switched to 2D (top-down orthographic) view");
    } else {
        // Switch back to 3D (perspective view)
        viewer.cameraFlight.flyTo({
            eye: [-3.933, 2.855, 27.018], // Reset to original 3D camera position
            look: [4.400, 3.724, 8.899],
            up: [-0.018, 0.999, 0.039],
            projection: "perspective" // Use perspective projection for 3D
        });
        console.log("Switched to 3D (perspective) view");
    }
    is3D.value = !is3D.value; // Toggle the state

};

const downloadPointcloud = async () => {
    await store.dispatch('project/generateDownloadToken', { id: projectId, type: 'pointcloud' });
};

onMounted(() => {
    setupViewer();
});
onBeforeUnmount(() => {
    if (distanceMeasurementControl) {
        distanceMeasurementControl.deactivate();
    }
    cleanupViewer();
});
</script>

<style scoped lang="scss">
.xeokit-viewer-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    /* Ensures no unwanted scroll */
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    user-select: none;
}

.xeokit-viewer {
    width: 100%;
    height: 100%;
    background: lightblue;
    background-image: linear-gradient(lightblue, white);
    position: absolute;
}

.nav-cube {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 250px;
    height: 250px;
    z-index: 99999999;
}

.minimap {
    position: absolute;
    top: 110px;
    left: 10px;
    width: 150px;
    height: 150px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid #ccc;
    z-index: 9999;
    backdrop-filter: blur(10px);
}

.ruler-buttons {

    position: absolute;
    bottom: 10px;
    left: calc(50% - 170px);
    z-index: 9999;
    padding: 10px 20px;
    font-size: 1.2rem;

    border-radius: 100px;
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
    background-blend-mode: luminosity;
    /* Blur */
    backdrop-filter: blur(50px);

    .button {
        background: transparent;
        color: white;
        border: none;
        cursor: pointer;
        margin: 6px;

        &.is-selected {
                background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
                background-blend-mode: luminosity;
                border-radius: 34px;
            }
        &:hover {
            background: rgba(255, 255, 255, 0.40);
        }
    }
}


section {
    height: 100vh;
    /* Ensure the viewer takes up the full height */
}

canvas {
    width: 100%;
    height: 100%;
}

.ruler-buttons {
    // min-width: 340px;
    z-index: 999999;
    transition: all 0.3s ease-in-out;
    // background-color: #D9D9D9;
    // position: fixed;
    // right: -310px;
    // left: -$sidebar-width;
    // height: calc(100vh - 76px);
    // width: 100px;
    background: var(--Windows-Glass, rgba(128, 128, 128, 0.30));
    background-blend-mode: luminosity;
    border: 1.4px solid rgba(255, 255, 255, 0.40);
    border-radius: 100px;
    backdrop-filter: blur(50px);

    // height: 68px !important;
    // background-color: rgba(188,188,188,0.3);

    .menu-list {
        li {
            display: inline-block;

            a,
            button {
                border-radius: 34px;
                height: 52px;
                width: 52px;
                display: inline-block;
                // background-color: white;
            }
        }

        border-radius: 100px;

        .icon-wrapper {
            height: 52px;
            width: 30px;
            margin-top: 4px;

            .nyne-icon {
                font-size: 1.2rem;
            }

        }

        .menu-item {
            // height: 52px;
        }

        [class^="nyne-icon-"]:before,
        [class*=" nyne-icon-"]:before {
            // height: 52px;
            // width: 38px;
            margin: 0;
            // margin-left: -12px;
        }

        .menu-item.disabled {
            color: #eee !important;
            background-color: transparent;
            cursor: not-allowed;

            &:hover {
                color: #eee !important;
                background-color: transparent;
            }
        }
    }

    & .menu .text {
        display: none;
    }

    .svg-inline--fa {
        height: 1.5em;
    }

    &.is-active {
        .menu-item {
            border-radius: 45px;
        }
    }

    // &.is-active {
    .router-link-exact-active {
        background-color: rgba(255, 255, 255, 0.40);
        color: black;
        border-radius: 34px;

        .menu-item {
            border-radius: 34px;
        }
    }

    &:hover {
        .menu-item {
            border-radius: 34px;
        }

        transition: all 0.3s ease-in-out;
        // left: 0px;

        // & .menu .text {
        // opacity: 1;
        // width: auto;
        // height: auto;
        // }
    }

    // & .menu .text {
    //   transition: all 0.3s ease-in-out;
    // opacity: 0.01;
    // width: 1px;
    // height: 1px;
    // }
}
</style>