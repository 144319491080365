<template>
    <section class="container">
      <div class="columns box is-centered" style="max-width: 1024px; margin: auto;">
        <!-- Left Column (1/3) -->
        <div class="column is-one-third">
          <div class="has-text-centered">
            <figure class="image is-inline-block">
              <img class="is-rounded" v-show="hasImage" @load="hasImage = true" @error="hasImage = false"
                                     v-loadimage="{ 'projectId': route.params.id }"
                                    alt="Project preview" />
              <img v-hide="hasImage" src="../assets/media/project_preview.png" class="is-rounded" />
            </figure>
            <!-- Project Name -->
            <p class="has-text-weight-bold mt-2">{{ projectName }}</p>
            <!-- Actions -->
            <div class="mt-4">
              <button class="button is-light is-fullwidth mb-2" @click="inviteUserToProject">
                Invite User to Project
              </button>
              <button class="button is-light is-fullwidth mb-2" @click="removeProject">
                Remove Project
              </button>
            </div>
          </div>
        </div>
  
        <!-- Right Column (2/3) -->
        <div class="column is-two-thirds">
          <div>
            <h2 class="title is-4 has-text-centered">Project Members</h2>
            <!-- List of Users (Persons) Associated with the Project -->
            <div v-if="sharedPersons.length > 0">
              <ul class="sharedProjects">
                <li v-for="person in sharedPersons" :key="person.id" class="box" style="position: relative;">
                  <!-- {{ person.firstName || 'N/A' }} {{ person.lastName || '' }} -->
                  <h3 class="subtitle is-5 m-0">{{ person.email }}</h3>
                  <div class="actions">
                    <font-awesome-icon
                      v-if="showDelete !== person.id"
                      class="icon-circle"
                      @click="showDelete = person.id"
                      icon="close"
                    />
                    <button
                      v-if="showDelete === person.id"
                      class="button button-delete is-danger"
                      @click="deleteAccess(person.id)"
                    >
                      {{ $t('General.Delete') }}
                    </button>
                  </div>
                </li>
              </ul>
            </div>
            <div v-else>
              <p class="has-text-centered">No members found for this project.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script setup lang="ts">
  import { ref, onMounted, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useStore } from '../store';
  
  // Access route parameters and store
  const route = useRoute();
  const store = useStore();
  const projectId = route.params.id; // Get projectId from the route
  const hasImage = ref(false);

  // Local state to manage project name and showing delete button
  const projectName = computed(() => store.state.projectShare.name);
  const showDelete = ref<string>('');
  
  // Computed property to fetch sharedPersons from the Vuex store
  const sharedPersons = computed(() => store.state.projectShare.sharedPersons);
  
  // Set layout when component is loaded
  store.commit('app/setLayout', 'centered');
  
  // Fetch shared persons for the project when the component is mounted
  onMounted(async () => {
    await store.dispatch('projectShare/getSharedPersonsForProject', projectId); // Load shared persons
    // You can update projectName here if it comes from the backend or other source
  });
  
  // Invite a user to the project
  const inviteUserToProject = async () => {
    const email = prompt("Enter the email of the person to invite:");
    if (email) {
      await store.dispatch('projectShare/inviteUsers', { projectId, emails: email });
      store.commit('app/showToast', {
        message: `Successfully invited ${email} to the project.`,
        type: 'success',
      });
    }
  };
  
  // Remove all users from the project
  const removeProject = async () => {
    const confirmed = confirm("Are you sure you want to remove the project?");
    if (confirmed) {
      await store.dispatch('projectShare/removeAllUsersFromProject', projectId);
      store.commit('app/showToast', {
        message: `Successfully removed the project.`,
        type: 'success',
      });
    }
  };
  
  // Delete access for a specific person in the project
  const deleteAccess = async (personId: string) => {
    const confirmed = confirm(`Are you sure you want to delete access for this person?`);
    if (confirmed) {
      await store.dispatch('projectShare/deleteAccess', { personId, projectId });
      store.commit('app/showToast', {
        message: `Successfully deleted ${personId}'s access to the project.`,
        type: 'success',
      });
    }
  };
  </script>
  
  <style scoped>
  .actions {
    z-index: 99;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 100%;
    text-align: right;
  }
  .icon-circle {
    margin-top: 0px;
    margin-right: 2px;
    font-size: 26px;
  }
  </style>
  