import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios from 'axios';
import decode from "jwt-decode";
import { AccountView } from '@/models/generated/accountView.model';

export interface AuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  token?: string;
  roles: string[];
  expires?: Date;
  userData?: AccountView;
  twoFactorQrCode?: string; // QR Code URL for 2FA setup
  twoFactorKey?: string; // 2FA key
}

function saveTokenToLocalStorage(token: string) {
  localStorage.setItem('nynecloud_accessToken', token);
}

function getTokenFromLocalStorage(): string | null {
  return localStorage.getItem('nynecloud_accessToken');
}

async function loginUser(credentials: any) {
  try {
    const response = await axios.post('/api/auth/login', credentials);
    return { success: response.status >= 200 && response.status < 300, response: response };
  } catch (error) {
    console.log("error", error);
    return { success: false, response: error as any };
  }
}

const mutations: MutationTree<AuthState> = {
  setAuthenticated(state: AuthState, authResponse: any) {
    if (!authResponse) {
      state.roles = [];
      state.token = undefined;
      state.expires = undefined;
      state.isAuthenticated = false;
      state.isAdmin = false;
    } else {
      state.roles = authResponse.roles;
      state.isAuthenticated = !!authResponse.token;
      state.isAdmin = authResponse.roles.includes("Administrator");
      state.token = authResponse.token;
      state.expires = authResponse.expirationDate;
      saveTokenToLocalStorage(authResponse.token);
    }
  },
  setUserData(state: AuthState, userData: AccountView) {
    state.userData = userData;
  },
  setUserDeletedDate(state: AuthState, date: Date) {
    if (state.userData) {
      state.userData.dateDeleted = date;
    }
  },
  setTwoFactorEnabled(state: AuthState, enabled: boolean) {
    if (state.userData) {
      state.userData.twoFactorEnabled = enabled;
    }
  },
  setTwoFactorSetup(state: AuthState, { qrCodeUri, secretKey }: { qrCodeUri: string, secretKey: string }) {
    state.twoFactorQrCode = qrCodeUri;
    state.twoFactorKey = secretKey;
  },
  clearTwoFactorSetup(state: AuthState) {
    state.twoFactorQrCode = undefined;
    state.twoFactorKey = undefined;
  }
};

const actions: ActionTree<AuthState, any> = {
  async loginWithEmail({ commit }: { commit: any }, { email, password }: { email: string, password: string }) {
    const authResponse = await loginUser({ email, password });
    if (!authResponse.success || !authResponse || !authResponse.response.data || !authResponse.response.data.token) {
      return authResponse;
    }
    const tokenData: any = decode(authResponse.response.data.token);
    
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    commit('setAuthenticated', { roles: roles ?? [], token: authResponse.response.data.token, expires: expirationDate });
    return authResponse.response.data;
  },
  async sendMagicLink(_, { email }) {
    try {
      const response = await axios.post('/api/auth/send-magic-link', { email });
      return { success: true, message: response.data.message };
    } catch (error: any) {
      return { success: false, message: error.response?.data?.message || 'Error sending magic link.' };
    }
  },
  async loginWithAuthCode({ commit }, { email, authCode }) {
    try {
      const response = await loginUser({ email, authCode });
      if (!response.success || !response.response.data.token) {
        return response;
      }
  
      const tokenData: any = decode(response.response.data.token);
      const expirationDate = new Date(tokenData.exp * 1000);
      const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  
      commit('setAuthenticated', { roles: roles ?? [], token: response.response.data.token, expires: expirationDate });
      return response.response.data;
    } catch (error) {
      return { success: false, message: 'Invalid or expired authentication code.' };
    }
  },
  async register({ commit }: { commit: any }, { email, password, firstName, lastName }: { email: string, password: string, firstName: string, lastName: string }) {
    const response = await axios.post('/api/auth/register', { email, password, firstName, lastName });
    if (!response.data || !response.data.token) {
      return;
    }
    const tokenData: any = decode(response.data.token);
    
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    commit('setAuthenticated', { roles: roles ?? [], token: response.data.token, expires: expirationDate });
    return response.data.token;
  },
  async confirmRegistration(_, { email, token }: { email: string, token: string }) {
    await axios.post('/api/auth/verify-registration', { email, token });
  },
  async requestPasswordReset(_, { email }: { email: string }) {
    await axios.post('/api/auth/forgot-password', { email });
  },
  async resetPassword(_, { email, token, newPassword }: { email: string, token: string, newPassword: string }) {
    await axios.post('/api/auth/reset-password', { email, token, newPassword });
  },
  async resendVerifyRegistration(_, { email }: { email: string }) {
    await axios.post('/api/auth/resend-verify-registration', { email });
  },

  // Fetch user profile data
  async getProfile({ commit }: { commit: any }) {
    const response = await axios.get('/api/auth/me');
    commit('setUserData', response.data);
  },

  // Update profile, including new fields like DateFormat, Timezone, etc.
  async updateProfile({ commit }: { commit: any }, data) {
    const response = await axios.post('/api/auth/me', data);
    commit('setUserData', response.data);
  },

  // Delete profile
  async deleteProfile({ commit }: { commit: any }) {
    const response = await axios.delete('/api/auth/me');
    if (response.data) {
      commit('setUserDeletedDate', response.data);
    }
    return response.data;
  },

  // Revert profile deletion
  async revertDeleteProfile({ commit }: { commit: any }) {
    const response = await axios.delete('/api/auth/me/revert');
    if (response.status >= 200 && response.status < 300) {
      commit('setUserDeletedDate', undefined);
    }
    return response.data;
  },

  // Enable 2FA and generate QR code
  async generate2FASetup({ commit }) {
    try {
      const response = await axios.get('/api/auth/generate-2fa-setup');
      commit('setTwoFactorSetup', { qrCodeUri: response.data.qrCodeUri, secretKey: response.data.secretKey });
      return { success: true };
    } catch (error: any) {
      return { success: false, message: error.response?.data?.message || 'Error generating 2FA setup.' };
    }
  },

  // Enable 2FA after verifying code
  async enable2FA({ commit }: { commit: any }, verificationCode: string) {
    try {
      await axios.post('/api/auth/enable-2fa', { code: verificationCode });
      commit('setTwoFactorEnabled', true);
      commit('clearTwoFactorSetup'); // Clear the QR code after enabling 2FA
      return { success: true };
    } catch (error: any) {
      return { success: false, message: error.response?.data?.message || 'Error enabling 2FA.' };
    }
  },

  // Disable 2FA
  async disable2FA({ commit }: { commit: any }) {
    try {
      await axios.post('/api/auth/disable-2fa');
      commit('setTwoFactorEnabled', false);
      return { success: true };
    } catch (error: any) {
      return { success: false, message: error.response?.data?.message || 'Error disabling 2FA.' };
    }
  },

  // Verify 2FA code during login
  async verify2FA(_, { code }) {
    try {
      const response = await axios.post('/api/auth/verify-2fa', { code });
      return { success: true, token: response.data.token };
    } catch (error) {
      return { success: false, message: 'Invalid or expired 2FA code.' };
    }
  },

  logout({ commit }: { commit: any }) {
    localStorage.removeItem('nynecloud_accessToken');
    commit('setAuthenticated', undefined);
  }
};

const getters: GetterTree<AuthState, any> = {
  isAuthenticated: (state: AuthState) => () => {
    return state.isAuthenticated;
  },
};

const getAuthState = () => {
  const token = getTokenFromLocalStorage();

  if (token && token.length > 0) {
    const tokenData: any = decode(token);

    // Access the claims
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    if (expirationDate > new Date()) {
      return { isAuthenticated: true, isAdmin: roles?.includes("Administrator"), roles, token: token, expires: expirationDate };
    }
  }

  return {
    isAuthenticated: false,
    isAdmin: false,
    token: "",
    roles: null,
    expired: null,
  };
};

export const authStore = ({
  namespaced: true,
  state: () => (getAuthState()),
  getters,
  mutations,
  actions,
});
