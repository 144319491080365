<template>
    <aside class="menu">
        <ul class="menu-list">
            <li class="p-0" :class="index == 0 ? 'pt-0' : 'pt-2'" v-for="(menuItem, index) in menuItems.filter(x => !store.state.auth.isAdmin ? !x.admin : true)"
                :key="index">
                <router-link :class="(menuItem.auth && !isAuth) ? 'disabled' : ''" :disabled="menuItem.auth && !isAuth" :to="{name: menuItem.routeName}" class="px-2 py-1 menu-item">
                    <div class="">
                        <div class="icon-wrapper">
                            <font-awesome-icon class="fontawesome-icon" :class="menuItem.icon[1] ? menuItem.icon[1] : ''" v-if="!menuItem.nyneIcon" :icon="menuItem.icon" />
                            <i v-if="menuItem.nyneIcon" class="nyne-icon" :class="'nyne-icon-' + menuItem.nyneIcon"></i>
                        </div>
                        <span class="column text">{{ $t(menuItem.title) }}</span>
                    </div>
                </router-link>

                <!-- <a v-if="!menuItem.admin || (menuItem.admin === true && store.state.auth.isAdmin === true)"
                    @click="handleMenuItemClick(menuItem)" class="px-4 py-3 menu-item">
                    <div class="columns is-mobile">
                        <div class="column is-one-fifth has-text-centered">
                            <font-awesome-icon v-if="!menuItem.nyneIcon" :icon="menuItem.icon" />
                            <i v-if="menuItem.nyneIcon" class="nyne-icon" :class="'nyne-icon-' + menuItem.nyneIcon"></i>
                        </div>
                        <span class="column text">{{ $t(menuItem.title) }}</span>
                    </div>
                </a> -->
            </li>
            <li class="p-0">
                <a v-if="store.state.auth.isAdmin === true && locale == 'de'"
                    @click="changeLanguage('en')" class="px-2 py-1 menu-item">
                    <div class="">
                        <div class="has-text-centered">
                            <font-awesome-icon :icon="['fas','language']" />
                        </div>
                        <span class="column text">English</span>
                    </div>
                </a>
            </li>
            <li class="p-0">
                <a v-if="store.state.auth.isAdmin === true && locale == 'en'"
                    @click="changeLanguage('de')" class="px-2 py-1 menu-item">
                    <div class="">
                        <div class="has-text-centered">
                            <font-awesome-icon :icon="['fas','language']" />
                      
                            <!-- <i v-if="menuItem.nyneIcon" class="nyne-icon" :class="'nyne-icon-' + menuItem.nyneIcon"></i> -->
                        </div>
                        <span class="column text">Deutsch</span>
                    </div>
                </a>
            </li>
        </ul>
    </aside>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from '../store';
import { useRouter } from 'vue-router';

export interface MenuItem {
    icon: Array<string>,
    nyneIcon?: string,
    title: string,
    routeName?: string,
    auth?: boolean,
    admin?: boolean,
    action?: () => void,
}
const store = useStore();
const router = useRouter();
const isAuth = computed(() => store.state.auth.isAuthenticated);
// const emit = defineEmits(['selected']);

// const handleMenuItemClick = async (menuItem: MenuItem) => {
//     if (menuItem.action && typeof menuItem.action === 'function') {
//         menuItem.action();
//     } else if (menuItem.routeName) {
//         await handleRouteClick(menuItem.routeName);
//     }
//     emit('selected', menuItem);
// };

// const handleRouteClick = async (routeName: string) => {
//     await router.push({ name: routeName });
// };

import { useI18n } from 'vue-i18n';
import { i18n, loadLocaleMessages } from '@/i18n'; // Import the i18n instance

const { t, locale } = useI18n({ useScope: 'global' });

const changeLanguage = async (localeString: string) => {
    let newLocale = localeString ?? 'en';
    if (locale.value !== newLocale) {
        const messages = await loadLocaleMessages(newLocale);
        i18n.global.setLocaleMessage(newLocale, messages);
        locale.value = newLocale;
    } else if (!localeString) {
        newLocale = 'en';
        const messages = await loadLocaleMessages(newLocale);
        i18n.global.setLocaleMessage(newLocale, messages);
        locale.value = newLocale;
    }

    if (router.currentRoute.value.meta.title) {
        (document as any).title = t(router.currentRoute.value.meta.title.toString()) + ' | ' + 'Cloud NYNE';
    } else {
        document.title = 'Cloud NYNE'; // Set a default title if no specific title is provided
    }
};

const menuItems = ref<MenuItem[]>([
    // {
    //     icon: ['fas', 'house'],
    //     title: 'Home',
    //     routeName: 'Home',
    // },
    {
        icon: ['fas', 'add'],
        nyneIcon: 'upload',
        title: 'Upload.Menu',
        routeName: "Upload",
    },
    {
        icon: ['fas', 'cube'],
        // nyneIcon: 'projekte',
        title: 'Projects.Title',
        routeName: 'Projects',
        auth: true,
    },
    {
        icon: ['fas', 'user-shield'],
        nyneIcon: 'team',
        title: 'Accounts.Menu',
        routeName: 'Accounts',
        auth: true,
        admin: true,
    },
    {
        icon: ['fa', 'users'],
        // nyneIcon: 'team',
        title: 'Team.Menu',
        routeName: 'Team',
        auth: true,
    },
    {
        icon: ['fas', 'user'],
        nyneIcon: 'einstellungen',
        title: 'Profile.Menu',
        routeName: 'Profile',
        auth: true,
    },
    {
        icon: ['fas', 'table'],
        // nyneIcon: 'analytics',
        title: 'Statistics.Menu',
        routeName: 'Statistics',
        auth: true,
        admin: true,
    },
    {
        icon: ['fas', 'sign-in'],
        // nyneIcon: 'analytics',
        title: 'Login',
        routeName: 'Login',
    },
    // {
    //     icon: ['fas', 'question-circle'],
    //     nyneIcon: 'wiki',
    //     title: 'Faq.Menu',
    //     routeName: 'FAQ',
    // },
    // {
    //     icon: ['fas', 'language'],
    //     title: 'Change Language (' + currentLocale.value + ')',
    //     action: changeLanguage,
    //     admin: true,
    // },
    // {
    //     icon: ['fas', 'sign-out'],
    //     title: 'General.Menu.LogOut',
    //     action: handleLogout,
    // },
]);
</script>

<style scoped lang="scss">
@import '../assets/css/variables.scss';

.menu>ul>li {
    // border-bottom: 1px solid lightgray;

    a {
        text-decoration: none;
    }
}

.menu-item {
    transition: all 0.3s ease-in-out;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #333;
    font-weight: 500;
}

.menu-item:hover {
    font-weight: bold;
    color: black;
    background-color: $primary;
}
.router-link-exact-active {
    background-color: $primary !important;
    color: black !important;
}

</style>
