<template>
    <section class="container">
        <div class="columns box is-centered" style="max-width: 1024px; margin: auto;">
            <!-- Left Column (1/3) -->
            <div class="column is-one-third">
                <div class="has-text-centered">
                    <!-- Profile Image -->
                    <figure class="image is-inline-block">
                        <img v-if="person?.profileImage" :src="person.profileImage" class="is-rounded"
                            alt="Person's Profile" />
                        <img v-else src="../assets/media/profile.png" class="is-rounded" alt="Default Profile" />
                    </figure>
                    <!-- Email -->
                    <p class="has-text-weight-bold mt-2">{{ person?.email }}</p>
                    <!-- Actions/Links -->
                    <div class="mt-4">
                        <button class="button is-light is-fullwidth mb-2" @click="inviteToAllProjects">
                            Invite to All Projects
                        </button>
                        <button class="button is-light is-fullwidth mb-2" @click="removeAccessFromAllProjects">
                            Remove from All Projects
                        </button>
                    </div>
                </div>
            </div>

            <!-- Right Column (2/3) -->
            <div class="column is-two-thirds">
                <div>
                    <h2 class="title is-4 has-text-centered">Shared Projects</h2>
                    <!-- List of Shared Projects -->
                    <div v-if="person?.sharedProjects && person.sharedProjects.length > 0">
                        <ul class="sharedProjects">
                            <li v-for="project in person.sharedProjects" :key="project.id" class="box" style="position: relative;">
                                <h3 class="subtitle is-5 m-0">{{ project.name ?? "N/A" }}</h3>
                                <div class="actions">
                                    <font-awesome-icon v-if="showDelete !== project.id" class="icon-circle" @click="showDelete = project.id"
                                        icon="close">
                                    </font-awesome-icon>
                                    <button v-if="showDelete === project.id" class="button button-delete is-danger" @click="deleteAccess(project.id)">
                                        {{ $t('General.Delete') }}
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <p class="has-text-centered">No shared projects found.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store';

const route = useRoute();
const store = useStore();
const personId = route.params.id;
const showDelete = ref("");

const person = computed(() => store.state.team.selectedPerson);
store.commit('app/setLayout', 'centered');

// Fetch person details on component mount
onMounted(async () => {
    const selectedPerson = store.getters['team/personById'](personId);
    if (!selectedPerson) {
        await store.dispatch('team/getPerson', personId); // Load from API if not in store
    } else {
        store.commit('team/setSelectedPerson', selectedPerson); // Use existing data in store
    }
});

// Remove the person's access from all projects
const removeAccessFromAllProjects = async () => {
    await store.dispatch('team/removePersonFromAllProjects', personId);
    store.commit('app/showToast', {
        message: `Successfully removed ${person.value?.email} from all projects.`,
        type: 'success',
    });
};

// Delete access from a specific project
const deleteAccess = async (projectId: string) => {
    if (projectId && personId) {
        await store.dispatch('team/deleteAccess', { personId, projectId });
        store.commit('app/showToast', {
            message: `Successfully deleted access to the project.`,
            type: 'success',
        });
    }
};

// Invite the user to all projects
const inviteToAllProjects = async () => {
    if (person.value?.email) {
        await store.dispatch('team/inviteUserToAllProjects', person.value.email);
        store.commit('app/showToast', {
            message: `Successfully invited ${person.value.email} to all projects.`,
            type: 'success',
        });
    }
};
</script>

<style lang="scss" scoped>
/* Add any necessary custom styles here */

.actions {
    z-index: 99;
    position: absolute;
    top: 14px;
    right: 14px;
    width: 100%;
    text-align: right;
    .icon-circle {
        margin-top: 0px;
        margin-right: 2px;
        font-size: 26px;
    }
}
</style>