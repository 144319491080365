<template>
    <section class="container">
        <div class="columns box is-centered" style="max-width: 1024px; margin: auto;">
            <!-- Left Column (1/3) -->
            <div class="column is-one-third">
                <div class="has-text-centered">
                    <!-- Profile Image -->
                    <figure class="image is-128x128 is-inline-block">
                        <img class="is-rounded" src="../assets/media/profile.png" alt="User Profile">
                    </figure>
                    <!-- Email -->
                    <p class="has-text-weight-bold mt-2">{{ formData.email }}</p>
                    <!-- Links -->
                    <div class="mt-4">
                        <button class="button is-light is-fullwidth mb-2">Terms Of Service</button>
                        <button class="button is-light is-fullwidth mb-2">Privacy Policy</button>
                        <button class="button is-light is-fullwidth mb-2">Licenses</button>
                        <button class="button is-light is-fullwidth mb-2">Legal Notice</button>
                    </div>
                </div>
            </div>

            <!-- Right Column (2/3) -->
            <div class="column is-two-thirds">
                <div class="">
                    <h2 class="title is-4 has-text-centered">Settings</h2>

                    <!-- First Name -->
                    <!-- <div class="field material-input">
                        <div class="control">
                            <input class="input" type="text" v-model="formData.firstName">
                            <label class="label">First Name</label>
                        </div>
                    </div> -->

                    <!-- Last Name -->
                    <!-- <div class="field material-input">
                        <div class="control">
                            <input class="input" type="text" v-model="formData.lastName">
                            <label class="label">Last Name</label>
                        </div>
                    </div> -->

                    <!-- Newsletter -->
                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                {{ $t('General.Newsletter') }}
                            </span>
                            <input type="checkbox" class="" v-model="formData.hasNewsletter" />
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                {{ $t('General.Notifications') }}
                            </span>
                            <input type="checkbox" v-model="formData.hasNotifications" />
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field checkbox-wrapper mt-4">
                        <label class="checkbox-label label switch is-rounded">
                            <span class="control-label">
                                Two Factor Authentication
                            </span>
                            <input type="checkbox" v-model="formData.twoFactorEnabled" @change="toggle2FA">
                            <span class="check"></span>
                        </label>
                    </div>

                    <div class="field material-select mt-5">
                        <select class="select" v-model="formData.language">
                            <option value="en">English</option>
                            <option value="de">Deutsch</option>
                        </select>
                        <label class="label">Language</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <div class="field material-select">
                        <select class="select" v-model="formData.dateFormat">
                            <option value="dd/MM/yyyy">dd/MM/yyyy</option>
                            <option value="MM/dd/yyyy">MM/dd/yyyy</option>
                            <option value="yyyy-MM-dd">yyyy-MM-dd</option>
                        </select>
                        <label class="label">Date Format</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <div v-if="false" class="field material-select">
                        <select class="select" v-model="formData.timezone">
                            <option value="UTC">UTC</option>
                            <option value="America/New_York">America/New_York</option>
                            <!-- Add more timezones here -->
                        </select>
                        <label class="label">Time Zone</label>
                        <div class="icon">
                            <font-awesome-icon icon="chevron-down"></font-awesome-icon>
                        </div>
                    </div>

                    <!-- Delete Account -->
                    <div class="field">
                        <button class="button is-danger is-fullwidth" @click="deleteProfile">Delete Account</button>
                        <p class="help is-danger mt-1">DANGER ZONE: Lose access to all your files. Type in your email
                            address to delete account.</p>
                    </div>

                    <!-- Submit Button -->
                    <div class="field has-text-centered">
                        <button class="button is-primary" @click="onSubmit">Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, markRaw } from 'vue';
import { useStore } from '../store';
import { useI18n } from 'vue-i18n';
import QRCodeModalContent from './QRCodeModalContent.vue';


const { t } = useI18n();
const store = useStore();
store.commit('app/setLayout', 'centered');

const formData = ref({
    email: '',
    firstName: '',
    lastName: '',
    hasNewsletter: false,
    hasNotifications: false,
    twoFactorEnabled: false,
    language: 'en',
    dateFormat: 'dd/MM/yyyy',
    timezone: 'UTC',
});

// Load user profile data on component mount
onMounted(async () => {
    await store.dispatch('auth/getProfile');
    Object.assign(formData.value, store.state.auth.userData);
});

watch(() => store.state.auth.userData, (newValue) => {
    if (newValue) {
        Object.assign(formData.value, newValue);
    }
});

// Submit form data
const onSubmit = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/updateProfile', formData.value);
    store.commit('app/showToast', {
        message: t('Profile.Success'),
        type: 'success',
    });
};

// Delete profile
const deleteProfile = async (event: any) => {
    if (event) {
        event.preventDefault();
    }
    await store.dispatch('auth/deleteProfile');
    store.commit('app/showToast', {
        message: t('Profile.SuccessAccountDeleted'),
        type: 'success',
    });
};
const toggle2FA = async () => {
    if (formData.value.twoFactorEnabled) {
        // Enable 2FA (Trigger QR code generation and modal display)
        const response = await store.dispatch('auth/generate2FASetup');
        if (response.success && store.state.auth.twoFactorQrCode) {
            // Show modal with QR Code
            store.commit('app/showConfirmDialog', {
                title: t('Profile.Enable2FA'),
                component: markRaw(QRCodeModalContent), // Mark the component as raw to avoid reactivity issues
                action: async () => {
                    // Get the 2FA code entered by the user
                    const twoFactorCode = (document.getElementById('twoFactorCodeInput') as HTMLInputElement)?.value;

                    if (twoFactorCode) {
                        const enableResponse = await store.dispatch('auth/enable2FA', twoFactorCode);
                        if (enableResponse.success) {
                            store.commit('app/showToast', {
                                message: t('Profile.2FAEnabledSuccess'),
                                type: 'success',
                            });
                        } else {
                            formData.value.twoFactorEnabled = false;
                            store.commit('app/showToast', {
                                message: t('Profile.2FAEnabledError'),
                                type: 'error',
                            });
                        }
                    } else {
                        store.commit('app/showToast', {
                            message: t('Profile.2FACodeRequired'),
                            type: 'error',
                        });
                    }
                },
            });
        } else {
            formData.value.twoFactorEnabled = false;
            store.commit('app/showToast', {
                message: t('Profile.Enable2FAError'),
                type: 'error',
            });
        }
    } else {
        // Disable 2FA
        const response = await store.dispatch('auth/disable2FA');
        if (!response.success) {
            formData.value.twoFactorEnabled = true;
            store.commit('app/showToast', {
                message: t('Profile.Disable2FAError'),
                type: 'error',
            });
        }
    }
};

</script>

<style scoped>
/* Add any necessary custom styles here */
</style>