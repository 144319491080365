// src/store/index.ts
import { AuthState, authStore } from './modules/auth';
// import { emissionStore } from './modules/emission';
import { AppState, appStore } from './modules/app';

import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { AccountsState, accountsStore } from './modules/account';
import { UploadState, uploadsStore } from './modules/upload';
import { ProjectsState, projectsStore } from './modules/project';
import { StatisticsState, statisticsStore } from './modules/statistics';
import { TeamState, teamStore } from './modules/team';
import { ProjectShareState, projectShareStore } from './modules/projectshare';



export interface State {
  account: AccountsState
  auth: AuthState;
  team: TeamState;
  upload: UploadState;
  project: ProjectsState;
  projectShare: ProjectShareState
  statistics: StatisticsState;
  app: AppState;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  modules: {
    account: accountsStore,
    auth: authStore,
    team: teamStore,
    project: projectsStore,
    projectShare: projectShareStore,
    upload: uploadsStore,
    statistics: statisticsStore,
    app: appStore,
  },
});

// define own `useStore` composition function, so that we don't have to pass key every time
export function useStore() {
  return baseUseStore(key)
}