<template>
  <div class="box box-compact login">
    <h1 class="title is-3 has-text-centered pt-5">{{ activeStep === 1 ? $t('LoginMagicLink.Title') : $t('ConfirmAuthCode.Title') }}</h1>
    <h2 class="subtitle small is-5 has-text-centered mb-4">{{ activeStep === 1 ? $t('LoginMagicLink.Subtitle') : $t('ConfirmAuthCode.Subtitle') }} <router-link
              v-if="activeStep === 1" to="/login-email">{{ $t('LoginMagicLink.Link') }}</router-link></h2>
    
    <p class="has-text-centered"></p>

    <!-- Step 1: Enter Email -->
    <form v-if="activeStep === 1" v-form-validate="{ submitMethod: sendMagicLink, locale: locale }" class="auth-code-inputs">
      <div class="field material-input fullwidth mt-6 pl-2 pr-2">
        <div class="control has-icons-left">
          <input placeholder="" class="input" type="text" id="email" v-model="email" required />
          <label class="label" for="email">{{ $t('General.Email') }}</label>
        </div>
      </div>
      <div class="field has-text-centered mt-5 pt-4">
        <button type="submit" class="button is-primary">{{ $t('LoginMagicLink.Submit') }}</button>
      </div>
    </form>

    <!-- Step 2: 6-digit Auth Code Input -->
    <AuthCodeInput
      v-if="activeStep === 2"
      :email="email"
      @submitAuthCode="submitAuthCode"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '../store';
import { useI18n } from 'vue-i18n';
import AuthCodeInput from './AuthCodeInput.vue';

const { locale } = useI18n();
const router = useRouter();
const store = useStore();

const email = ref(''); // Step 1 email input value
const activeStep = ref(1);  // Start at Step 1

const sendMagicLink = async () => {
  const result = await store.dispatch('auth/sendMagicLink', { email: email.value });
  // const result = { success: true }; // for quick debugging of frontend
  if (result.success) {
    activeStep.value = 2; // Proceed to Step 2
  }
};

const submitAuthCode = async (code: string) => {
  console.log("submitAuthCode", code);
  const result = await store.dispatch('auth/loginWithAuthCode', { email: email.value, authCode: code });
  if (result && result.token) {
    await router.push({ name: 'Projects' });
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.auth-code-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-inputs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.code-input {
  width: 40px;
  height: 44px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.email-text {
  margin-top: 20px;
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>
