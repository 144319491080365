<template>
    <section class="section">
        <div class="container">
            <div class="columns is-multiline">
                <div class="column is-one-quarter" v-for="person in persons" :key="person.id">
                    <div class="box">
                        <div class="content has-text-centered" style="position:relative;">
                            <div class="actions">
                                <font-awesome-icon v-if="!showDelete" class="icon-circle"
                                    @click="showDelete = person.id" icon="close" />
                                <button v-if="showDelete" @click="removePerson(person.id)"
                                    class="button button-delete is-danger">{{ $t('General.Delete') }}</button>
                            </div>
                            <router-link :to="'/team/' + person.id">
                                <figure class="image is-inline-block">
                                    <img class="is-rounded" v-if="person.profileImage" :src="person.profileImage"
                                        alt="Person's profile image" style="height: 230px;" />
                                    <img class="is-rounded" v-else src="../assets/media/profile.png"
                                        alt="Default profile image" style="height: 230px;" />
                                </figure>
                                <h2 class="subtitle is-5">{{ person.email }}</h2>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div v-if="persons.length === 0" class="column">
                    <h2 class="title has-text-centered">No persons found.</h2>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from '@/store';

// Access the store
const store = useStore();

// Computed property for getting the persons from the Vuex store
const persons = computed(() => store.state.team.persons);

const showDelete = ref("")
// Set layout class in the store
store.commit('app/setLayout', 'default');

// Function to load the persons
const loadPersons = async () => {
    await store.dispatch('team/getPersons');
};

// Function to remove a person from all projects
const removePerson = async (personId: string) => {
    await store.dispatch('team/removePersonFromAllProjects', personId);
    store.commit('app/showToast', {
        message: 'Person removed from all projects successfully.',
        type: 'success',
    });
};

// Load persons when the component is mounted
onMounted(() => {
    loadPersons();
});
</script>

<style scoped lang="scss">
.actions {
    z-index: 99;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: #666;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100%;
    text-align: right;

    .button-delete {
        width: 100%;
    }

    &:hover {
        color: #000;
    }

    .button-delete {
        margin-top: -10px;
    }
}

.box {
    height: 320px;
}
</style>
