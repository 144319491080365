import { Toast } from "@/models";
import { ActionTree, MutationTree } from "vuex";

export interface AppState {
  toasts: Toast[],
  disableLoadingSpinner: boolean,
  showLoadingSpinner: boolean,
  showConfirmDialog: boolean,
  confirmDialogPayload: any, // { title / text / action() }
  acceptedDataPrivacy: boolean,
  acceptCookies: boolean,
  doNotShowCookies: boolean,
  layoutClass: string,
}

function saveDataPrivacyAcceptance() {
  const sixMonthsFromNow = new Date();
  sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);
  localStorage.setItem('acceptedDataPrivacy', sixMonthsFromNow.toISOString());
}

function checkDataPrivacyAcceptance(): boolean {
  const acceptedDate = localStorage.getItem('acceptedDataPrivacy');
  if (!acceptedDate) {
    return false;
  }
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  return (new Date(acceptedDate) > sixMonthsAgo);
}

const mutations: MutationTree<AppState> = {
  showToast(state: AppState, payload: any) {
    state.toasts.push(payload);
  },
  dismissToast(state: AppState, index: number) {
    state.toasts.splice(index, 1);
  },
  showLoadingSpinner(state: AppState) {
    if (!state.disableLoadingSpinner) {
      state.showLoadingSpinner = true;
    }
  },
  hideLoadingSpinner(state: AppState) {
    state.showLoadingSpinner = false;
  }, 
  showConfirmDialog(state: AppState, payload: any) {
    state.showConfirmDialog = true;
    state.confirmDialogPayload = payload;
  },
  hideConfirmDialog(state: AppState) {
    state.showConfirmDialog = false;
    state.confirmDialogPayload = null;
  },
  acceptDataPrivacy(state: AppState) {
    state.acceptedDataPrivacy = true;
    saveDataPrivacyAcceptance(); // Save the acceptance for 6 months
  },
  acceptCookies(state: AppState) {
    state.acceptCookies = true;
    state.doNotShowCookies = true;
  },
  declineCookies(state: AppState) {
    state.acceptCookies = false;
    state.doNotShowCookies = true;
  },
  setLayout(state: AppState, layoutClass: string) {
    state.layoutClass = layoutClass;
  },
  setAcceptedDataPrivacy(state: AppState, status: boolean) {
    state.acceptedDataPrivacy = status;
  }
}


const actions: ActionTree<AppState, any> = {
  checkDataPrivacyAcceptance({ commit }) {
    const isAccepted = checkDataPrivacyAcceptance();
    commit('setAcceptedDataPrivacy', isAccepted);
  }
};

export const appStore = ({
  namespaced: true,
  state: {
    toasts: [] as Toast[],
    showLoadingSpinner: false,
    disableLoadingSpinner: false,
    acceptedDataPrivacy: false,
    showConfirmDialog: false,
    layoutClass: 'centered'
  },
  mutations,
  actions
});