<template>
  <form @submit.prevent="onSubmitAuthCode" class="auth-code-inputs">
    <div class="field auth-inputs mt-5">
      <input
        v-for="(input, index) in code"
        :key="index"
        ref="inputRefs"
        type="text"
        maxlength="1"
        placeholder="-"
        class="input code-input p-0"
        v-model="code[index]"
        @input="handleInput($event, index)"
        @keydown="handleKeydown($event, index)"
        @paste="handlePaste"
      />
    </div>
    <h3 class="subtitle is-5 has-text-centered email-text mt-6">{{ email }}</h3>
    <div class="field has-text-centered mt-2" v-if="false">
      <button type="submit" class="button is-primary">{{ t('ConfirmAuthCode.Submit') }}</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { ref, nextTick, reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    email: {
        type: String,
        default: "",
    },
});

const email = ref<string>(props.email);  // Email passed from parent
const emit = defineEmits<{
  (e: 'submitAuthCode', code: string): void;
}>();

const { t } = useI18n();

const code = reactive<string[]>(["", "", "", "", "", ""]);   // Reactive array for the 6-digit code
const inputRefs = ref<(HTMLInputElement | null)[]>([]);  // Input references

// Handle input - restrict to digits only
const handleInput = (event: Event, index: number) => {
  const target = event.target as HTMLInputElement;
  const value = target.value.replace(/\D/g, '');  // Remove non-digit characters

  if (value.length === 1) {
    code[index] = value;
    focusNext(index);
  } else {
    target.value = '';
  }

  // Check if all fields are filled and submit automatically
  if (code.every((digit) => digit.length === 1)) {
    emit('submitAuthCode', code.join(''));
    clearInputs();
  }
};

// Move to next input after filling current one
const focusNext = (index: number) => {
  if (index < 5) {
    nextTick(() => {
      inputRefs.value[index + 1]?.focus();
    });
  }
};

// Handle keydown events to prevent unwanted characters
const handleKeydown = (event: KeyboardEvent, index: number) => {
  const key = event.key;

  // Check if Ctrl+V (or Cmd+V on Mac) is pressed for paste
  if ((event.ctrlKey || event.metaKey) && key.toLowerCase() === 'v') {
    // Allow paste, do not prevent default here
    return;
  }

  if (key === 'Backspace' && code[index] === '') {
    // Move focus to previous input when backspace is pressed
    if (index > 0) {
      nextTick(() => inputRefs.value[index - 1]?.focus());
    }
  } else if (!/^\d$/.test(key) && key !== 'Backspace') {
    // Prevent non-numeric characters, except backspace
    event.preventDefault();
  }
};

// Handle paste event
const handlePaste = (event: ClipboardEvent) => {
  const pasteData = (event.clipboardData?.getData('text') || '').slice(0, 6); // Only get up to 6 digits
  const pasteArray = pasteData.split('').filter(char => /^\d$/.test(char)); // Ensure digits only

  console.log("paste", pasteArray);
  pasteArray.forEach((char, index) => {
    if (index < code.length) {
      code[index] = char;  // Assign each digit to the correct input
    }
  });

  console.log("code", code);

  // Automatically move focus and emit code
  if (pasteArray.length === 6) {
    nextTick(() => inputRefs.value[5]?.focus());  // Move focus to the last input
    emit('submitAuthCode', code.join(''));
    clearInputs();
  } else {
    nextTick(() => inputRefs.value[pasteArray.length]?.focus());  // Move focus to the next input
  }

  event.preventDefault();
};

// Clear input fields after submission
const clearInputs = () => {
  for (let i = 0; i < 6; i++) {
    code[i] = '';
  }
  nextTick(() => inputRefs.value[0]?.focus());
};

// Submit the code when all inputs are filled
const onSubmitAuthCode = () => {
  if (code.every((digit) => digit.length === 1)) {
    emit('submitAuthCode', code.join(''));
    clearInputs();
  }
};
</script>

<style scoped lang="scss">
.auth-code-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.auth-inputs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.code-input {
  width: 40px;
  height: 44px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.email-text {
  margin-top: 20px;
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>
