<template>
    <div class="modal" :class="{ 'is-active': showDialog }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p v-if="payload && payload.title" class="modal-card-title">{{ payload && payload.title }}</p>
                <button class="button delete" :title="$t('General.CloseDialog')" @click="hideModal">
                    <font-awesome-icon icon="close"></font-awesome-icon>
                </button>
            </header>
            <section class="modal-card-body">
                <slot>
                    <component v-if="payload?.component" :is="payload.component"></component>
                    <div v-else class="columns is-mobile">
                        <div class="column is-narrow">
                            <i class="nyne-icon nyne-icon-achtung"></i>
                            <!-- <font-awesome-icon color="black" size="3x" icon="triangle-exclamation"></font-awesome-icon> -->
                        </div>
                        <div class="column">
                            <p v-if="payload && payload.text" v-html="payload.text"></p>
                        </div>
                    </div>
                </slot>
            </section>
            <footer class="modal-card-foot">
                <slot name="footer">
                    <button class="button" @click="hideModal">{{ $t('General.Abort') }}</button>
                    <button v-if="payload && payload.action" class="button is-primary" @click="handleAction">{{ $t('General.Submit') }}</button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '@/store';

const emit = defineEmits(['action']);

const store = useStore();

let lastActivePayload: any = null;
const showDialog = computed(() => store.state.app.showConfirmDialog);
const payload = computed(() => {
    if (store.state.app.confirmDialogPayload !== null) {
        lastActivePayload = store.state.app.confirmDialogPayload;
    }
    return lastActivePayload;
});

const hideModal = () => {
    store.commit('app/hideConfirmDialog');
};

const handleAction = () => {
    emit('action');
    store.state.app.confirmDialogPayload.action();
    hideModal();
};
</script>

<style scoped lang="scss">
.modal-card-foot {
    justify-content: flex-end;
}

.modal {
    display: flex;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.is-active {
        pointer-events: all;
        opacity: 1;
    }
}
</style>
